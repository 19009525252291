import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@oup/shared-front-end';
import { ICON_REFRESH_CIRCLE } from '@oup/shared-front-end/svg/oup';
import PanelHeading from '../../../components/PanelHeading/PanelHeading';
import Illustration from '../../../components/Illustration/Illustration';
import styles from './NoResultsFound.scss';

function NoResultsFound({ onResetFiltersClick }) {
  return (
    <div className={styles.container}>
      <div className={styles.illustration}>
        <Illustration illustrationSrc="/media/feedback/illustration-information-sitting-books.svg" />
      </div>
      <PanelHeading
        customClassName={styles.panel}
        title="No results found"
        subtitle="You may need to remove some filters or try another option"
      />
      <Button
        variant="filled"
        size="base"
        text="Reset filters"
        onClick={onResetFiltersClick}
        icon={{
          component: <ICON_REFRESH_CIRCLE />
        }}
      />
    </div>
  );
}

NoResultsFound.propTypes = {
  onResetFiltersClick: PropTypes.func.isRequired
};

export default NoResultsFound;
