import React, { useEffect, useRef } from 'react';
import { Button } from '@oup/shared-front-end';
import PropTypes from 'prop-types';
import styles from './CreateDeepLinksPage.scss';
import Illustration from '../../../components/Illustration/Illustration';

function CreateDeepLinksPage({ submitData }) {
  const timeoutRef = useRef(null);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => submitData(), 5000);
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const handleClick = () => {
    clearTimeout(timeoutRef.current);
    submitData();
  };

  return (
    <>
      <div className={styles.successIllustration}>
        <Illustration illustrationSrc="/media/feedback/illustration-success.svg" />
      </div>
      <h1 className={styles.title}>All set</h1>
      <div className={styles.linkCreationDescription}>Link creation will start automatically in 5 seconds</div>
      <Button onClick={handleClick} variant="filled" text="Start link creation" size="base" />
    </>
  );
}

CreateDeepLinksPage.propTypes = {
  submitData: PropTypes.func
};

export default CreateDeepLinksPage;
