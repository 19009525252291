import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@oup/shared-front-end';
import { ICON_LEFT } from '@oup/shared-front-end/svg/oup';
import { PLATFORMS } from '../../../../sharedNodeBrowser/constants.js';
import Badge from '../../../components/Badge/Badge';
import colors from '../../../globals/colors';
import styles from './ConfirmationFooter.scss';
import SelectionStatus from '../ProductsPage/SelectionStatus';

function ConfirmationFooter({ onBackClick, onSubmit, showActionsRow, isSubmitButtonDisabled }) {
  const { loading } = useSelector(state => state.getLinkedProducts);
  const { confirmedProducts } = useSelector(state => state.ngiContentSelector);

  const numberOfProductsWithoutSelectedItems = confirmedProducts.filter(product => !product.addContentItems).length;

  const numberOfSelectedPages = confirmedProducts.reduce((memo, product) => {
    const noProductErrors = !product.errors || !Object.values(product.errors).find(e => !!e);
    if (product.platform === PLATFORMS.OLB && product.addContentItems && noProductErrors) {
      return memo + product.selectedEndPage - product.selectedStartPage + 1;
    }

    return memo;
  }, 0);

  const countSelectedLinksForVSTProduct = (product, checkGrades) =>
    product.vstUnits.reduce(
      (unitSum, unit) =>
        unitSum +
        unit.vstLessons.reduce(
          (lessonSum, lesson) =>
            lessonSum +
            lesson.vstActivities.reduce(
              (activitySum, activity) =>
                activity.isChecked && (checkGrades ? activity.maxMarks > 0 : true) ? activitySum + 1 : activitySum,
              0
            ),
          0
        ),
      0
    );

  const numberOfSelectedActivities = checkGrades =>
    confirmedProducts
      .filter(product => product.platform === 'VST' && product.addContentItems)
      .reduce(
        (productSum, product) =>
          productSum +
          ((product.addContentItems && product.includeOnlineClassroom && 1) || 0) +
          (product.vstUnits && product.addContentItems ? countSelectedLinksForVSTProduct(product, checkGrades) : 0),
        0
      );

  const countSelectedNGCGrades = line =>
    line.isActivity && line.maxMarks > 0 && line.isChecked
      ? 1
      : 0 + line.childs.reduce((sAcc, child) => sAcc + countSelectedNGCGrades(child), 0);

  const countAllNGCGradesWithinLine = line =>
    line.isActivity && line.maxMarks > 0
      ? 1
      : 0 + line.childs.reduce((sAcc, child) => sAcc + countAllNGCGradesWithinLine(child), 0);

  const countSelectedNGC = line =>
    line.isActivity && line.isChecked ? 1 : 0 + line.childs.reduce((sAcc, child) => sAcc + countSelectedNGC(child), 0);

  const numberOfSelectedNGCActivities = confirmedProducts
    .filter(product => product.platform === 'ELTCORE' && product.addContentItems)
    .reduce(
      (acc, product) => acc + product.ngcProductInformations.reduce((pAcc, line) => pAcc + countSelectedNGC(line), 0),
      0
    );

  const gradesCount = confirmedProducts
    .filter(product => product.addGradeItems)
    .reduce((acc, product) => {
      if (product.platform === PLATFORMS.VST) {
        if (product.addContentItems) {
          return acc + countSelectedLinksForVSTProduct(product, true);
        }
        return acc + product.activitiesNo;
      }
      if (product.platform === PLATFORMS.OLB) {
        return acc + product.activitiesNo;
      }
      if (product.platform === PLATFORMS.ELTCORE) {
        if (product.addContentItems) {
          return acc + product.ngcProductInformations.reduce((pAcc, line) => pAcc + countSelectedNGCGrades(line), 0);
        }
        return acc + product.ngcProductInformations.reduce((pAcc, line) => pAcc + countAllNGCGradesWithinLine(line), 0);
      }
      return 0;
    }, 0);

  const linksCount =
    numberOfProductsWithoutSelectedItems +
    numberOfSelectedActivities() +
    numberOfSelectedPages +
    numberOfSelectedNGCActivities;

  const handleAddLearningMaterialsButtonClick = () => {
    onSubmit();
  };

  const anyProductWithAddGradeItemsEnabled = confirmedProducts.some(product => product.addGradeItems);

  const ngcProductWithUncheckedItems = confirmedProducts.some(
    product =>
      product.platform === PLATFORMS.ELTCORE &&
      product.addContentItems &&
      product.ngcProductInformations.reduce((acc, line) => acc + countSelectedNGC(line), 0) === 0
  );

  // This flag detects if there is at least one product which has addContentItems field enabled but all lessons are unselected
  const vstProductWithUncheckedItems = confirmedProducts.some(
    product =>
      product.platform === PLATFORMS.VST &&
      product.addContentItems &&
      !product.includeOnlineClassroom &&
      !product.vstUnits.some(unit =>
        unit.vstLessons.some(lesson => lesson.vstActivities.some(activity => activity.isChecked))
      )
  );

  return (
    <div className={styles.container}>
      {!loading && showActionsRow && (
        <div className={styles.select}>
          <span>
            <span>
              <Badge backgroundColor={colors.PRIMARY_BLUE} value={linksCount} /> Links
            </span>
            {anyProductWithAddGradeItemsEnabled && (
              <span>
                <Badge backgroundColor={colors.PRIMARY_BLUE} value={gradesCount} /> Grade items
              </span>
            )}
          </span>
          <SelectionStatus linksCount={linksCount} />
        </div>
      )}

      <div className={styles.confirmation}>
        <Button
          variant="transparent"
          text="Back"
          size="base"
          icon={{
            placement: 'left',
            component: <ICON_LEFT />
          }}
          onClick={onBackClick}
        />
        <Button
          variant="filled"
          text="Add learning materials"
          size="base"
          onClick={handleAddLearningMaterialsButtonClick}
          disabled={!!isSubmitButtonDisabled || vstProductWithUncheckedItems || ngcProductWithUncheckedItems}
        />
      </div>
    </div>
  );
}

ConfirmationFooter.propTypes = {
  onBackClick: PropTypes.func,
  showActionsRow: PropTypes.bool,
  onSubmit: PropTypes.func,
  isSubmitButtonDisabled: PropTypes.oneOf(PropTypes.bool, PropTypes.number)
};

export default ConfirmationFooter;
