import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ValidationMessage } from '@oup/shared-front-end';
import styles from './LineItemCreationProgressPage.scss';
import IconErrorCircle from '../ConfirmationPage/icon-error-circle-24.svg';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner.js';
import ErrorView from '../components/ErrorView';
import getProductActivityCount from '../utils/getProductActivityCount.js';

function LineItemCreationProgressPage() {
  const { productsWithGradeItems, error } = useSelector(state => state.processLineItemsBatches);
  const [displayLoadingSpinner, updateDisplayLoadingSpinner] = useState(true);

  setTimeout(() => updateDisplayLoadingSpinner(false), 3000);

  const getCurrentValueOfProcessed = pIndex => {
    const currentProduct = productsWithGradeItems[pIndex];
    if (currentProduct.status === 'processing' && !currentProduct.processed) {
      return undefined;
    }
    if (currentProduct.processed === undefined) {
      if (pIndex === 0) {
        return undefined;
      }
      const previousProduct = productsWithGradeItems[pIndex - 1];
      if (previousProduct.processed === undefined || previousProduct.processed !== previousProduct.activitiesNo) {
        return 0;
      }
      return undefined;
    }
    return currentProduct.processed;
  };

  // Display Loading Spinner for 3 seconds before showing process line item screen
  if (displayLoadingSpinner) return <LoadingSpinner customClass={styles.loading} />;

  return (
    <>
      {!error && (
        <div className={styles.message}>
          <ValidationMessage state="information">
            Once your grade book has been updated this dialog will close, your LMS will then start adding your links.
            Please don't close this window until it is completed.
          </ValidationMessage>
        </div>
      )}
      {error && (
        <div className={styles.errorView}>
          <ErrorView
            title="Grade item creation stopped"
            bodyText="Please close this dialog and try again to resume."
            bodyTextSecondLine="Once all grade items have been added, link creation can start."
            hasTwoBodyTextLines
            processLineItemErrorMessage
          />
        </div>
      )}
      {!error && <h1 className={styles.title}>Create grade items</h1>}
      <div className={styles.productsContainer}>
        {productsWithGradeItems?.map((product, pIndex) => (
          <div key={product.productid} className={styles.gradeCreationProduct}>
            <div className={styles.productTitle}>{product.title}</div>
            <progress
              className={styles.progress}
              max={getProductActivityCount(product)}
              value={getCurrentValueOfProcessed(pIndex)}
            />

            {error && product.status === 'processing' && (
              <div className={styles.error}>
                <IconErrorCircle className={styles.errorIcon} />
                <p className={styles.errorMessage}>progress stopped</p>
              </div>
            )}

            <div className={styles.processedLineItems}>
              {product.processed || 0} - {getProductActivityCount(product)} grade items
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default LineItemCreationProgressPage;
