import React from 'react';
import PropTypes from 'prop-types';
import Unit from './Unit';

const VST_ROOT_LEVEL = 0;
const VST_UNIT_LEVEL = 1;
const VST_LESSON_LEVEL = 2;

function VSTLinksContainer({ product, onUnitCheckboxClick, confirmedProducts }) {
  const currentConfirmedProduct = confirmedProducts.find(cProduct => cProduct.isbnLaunch === product.isbnLaunch);

  const countSelected = (level, targetProduct, unitIndex, lessonIndex) => {
    if (level === VST_ROOT_LEVEL) {
      return targetProduct.vstUnits.reduce(
        (uAcc, unit) =>
          uAcc +
          unit.vstLessons.reduce(
            (lAcc, lesson) => lAcc + lesson.vstActivities.filter(activity => activity.isChecked).length,
            0
          ),
        0
      );
    }
    if (level === VST_UNIT_LEVEL) {
      return targetProduct.vstUnits[unitIndex].vstLessons.reduce(
        (lAcc, lesson) => lAcc + lesson.vstActivities.filter(activity => activity.isChecked).length,
        0
      );
    }
    if (level === VST_LESSON_LEVEL) {
      return targetProduct.vstUnits[unitIndex].vstLessons[lessonIndex].vstActivities.filter(
        activity => activity.isChecked
      ).length;
    }
    return 0;
  };

  return (
    <ul>
      <Unit
        key={`key_select_all_${product.isbnLaunch}`}
        id={`id_select_all_${product.isbnLaunch}`}
        unit={{ levelName: 'Select all' }}
        onUnitCheckboxClick={
          value =>
            onUnitCheckboxClick({
              newCheckedValue: !value,
              selectAll: true
            })
          // eslint-disable-next-line react/jsx-curly-newline
        }
        isUnitChecked={!!currentConfirmedProduct.isChecked}
        indeterminate={currentConfirmedProduct.partialSelected}
        selectAll
      />
      <ul>
        {currentConfirmedProduct.userType === 'TEACHER' && (
          <Unit
            key={`key_classroom_${product.isbnLaunch}`}
            id={`id_classroom_${product.isbnLaunch}`}
            unit={{ levelName: 'Online Practice classroom' }}
            onUnitCheckboxClick={
              value =>
                onUnitCheckboxClick({
                  newCheckedValue: !value,
                  classRoomCaseEntry: true
                })
              // eslint-disable-next-line react/jsx-curly-newline
            }
            isUnitChecked={currentConfirmedProduct.includeOnlineClassroom}
            numberOfActivities={1}
            isClassRoom
          />
        )}
        <ul>
          {currentConfirmedProduct.vstUnits.map((unit, unitIndex) => (
            <Unit
              key={`unit_key_${unit.uId}`}
              id={`id_unit_${unit.uId}`}
              isbnLaunch={product.isbnLaunch}
              unit={unit}
              onUnitCheckboxClick={
                value =>
                  onUnitCheckboxClick({
                    newCheckedValue: !value,
                    unitIndex
                  })
                // eslint-disable-next-line react/jsx-curly-newline
              }
              onToggleClick={() => onUnitCheckboxClick({ toggle: { value: !!unit.accordionOpened, unitIndex } })}
              isUnitChecked={!!unit.isChecked}
              indeterminate={unit.partialSelected}
              numberOfActivities={countSelected(VST_UNIT_LEVEL, currentConfirmedProduct, unitIndex)}
              hasClosingToggle
            >
              <ul>
                {unit.vstLessons.map((lesson, lessonIndex) => (
                  <Unit
                    hasClosingToggle
                    key={`lesson_key_${lesson.uId}`}
                    id={`id_lesson_${lesson.uId}`}
                    isbnLaunch={product.isbnLaunch}
                    unit={lesson}
                    onUnitCheckboxClick={
                      value =>
                        onUnitCheckboxClick({
                          newCheckedValue: !value,
                          unitIndex,
                          lessonIndex
                        })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    onToggleClick={
                      () => onUnitCheckboxClick({ toggle: { value: !!lesson.accordionOpened, unitIndex, lessonIndex } })
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    isUnitChecked={!!lesson.isChecked}
                    indeterminate={lesson.partialSelected}
                    numberOfActivities={countSelected(
                      VST_LESSON_LEVEL,
                      currentConfirmedProduct,
                      unitIndex,
                      lessonIndex
                    )}
                  >
                    <ul>
                      {lesson.vstActivities.map((activity, activityIndex) => (
                        <Unit
                          key={`key_activity_${activity.uId}`}
                          id={`id_activity_${activity.uId}`}
                          isbnLaunch={product.isbnLaunch}
                          unit={activity}
                          onUnitCheckboxClick={
                            value =>
                              onUnitCheckboxClick({
                                newCheckedValue: !value,
                                unitIndex,
                                lessonIndex,
                                activityIndex
                              })
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                          isUnitChecked={!!activity.isChecked}
                        />
                      ))}
                    </ul>
                  </Unit>
                ))}
              </ul>
            </Unit>
          ))}
        </ul>
      </ul>
    </ul>
  );
}

VSTLinksContainer.propTypes = {
  isbnLaunch: PropTypes.string,
  onUnitCheckboxClick: PropTypes.func,
  product: PropTypes.object,
  confirmedProducts: PropTypes.array
};

export default VSTLinksContainer;
