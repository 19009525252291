import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LocationChangeListener from './locationChangeListener';
import BlankPage from '../routes/BlankPage/BlankPage';
import NgiContentSelector from './NGIContentSelector/NGIContentSelector';

function RouteManager() {
  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Routes>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="blank" element={<BlankPage />} />
          <Route path="/content-selector" element={<NgiContentSelector />} />
        </Routes>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
