import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button } from '@oup/shared-front-end';
import { ACCORDION_TOGGLE } from '@oup/shared-front-end/svg/oup';
import styles from './Unit.scss';

function Unit({
  unit,
  isClassRoom,
  isUnitChecked,
  onUnitCheckboxClick,
  isbnLaunch,
  selectAll,
  indeterminate,
  id,
  children,
  numberOfActivities,
  onToggleClick
}) {
  const getLabel = () => {
    if (indeterminate) {
      return 'mixed';
    }
    if (isUnitChecked) {
      return 'checked';
    }
    return 'un-checked';
  };

  return (
    <li className={`${selectAll && styles.selectAllUnit}`}>
      <div className={`${styles.unit} ${isClassRoom && styles.classroomUnit}`}>
        <div
          className={`${styles.numberOfActivities} ${
            isUnitChecked ? styles.checkedUnit : styles.uncheckedUnit
          } ${unit.numberOfActivities > 9 && styles.overNine}`}
        >
          {numberOfActivities || ''}
        </div>

        <Checkbox
          id={id || `id-${isbnLaunch}`}
          checked={isUnitChecked}
          onChange={() => onUnitCheckboxClick(isUnitChecked)}
          label={selectAll ? getLabel() : `select ${unit.name}`}
          value={`select ${unit.name}`}
          indeterminate={indeterminate}
          hideLabel
        />
        <div className={`${styles.name} ${isClassRoom && isUnitChecked ? styles.classroomName : styles.name}`}>
          {unit.levelName}
        </div>
        {children && (
          <div className={`${styles.accordionToggle} ${unit.accordionOpened && styles.openedToggle}`}>
            <Button
              onClick={() => onToggleClick()}
              variant="transparent"
              size="base"
              icon={{
                placement: 'right',
                component: <ACCORDION_TOGGLE />
              }}
            />
          </div>
        )}
      </div>
      {children && unit.accordionOpened && <div className={styles.childrenUnits}>{children}</div>}
    </li>
  );
}

Unit.propTypes = {
  unit: PropTypes.object,
  isClassRoom: PropTypes.bool,
  isUnitChecked: PropTypes.bool,
  isbnLaunch: PropTypes.string,
  onUnitCheckboxClick: PropTypes.func,
  selectAll: PropTypes.bool,
  indeterminate: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.oneOf(PropTypes.array, PropTypes.bool),
  numberOfActivities: PropTypes.number,
  onToggleClick: PropTypes.func
};

export default Unit;
