import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from '@oup/shared-front-end';
import { getContentSelectorStatusRequest } from '../../../redux/actions/getContentSelectorStatus.actions.js';
import { invalidateContentSelectorGroupRequest } from '../../../redux/actions/invalidateContentSelectorGroup.actions.js';
import styles from './GroupRetryOptionsPage.scss';
import Illustration from '../../../components/Illustration/Illustration.js';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner.js';
import { storeLineItemsRequest } from '../../../redux/actions/storeLineItemsActions.js';
import { getJwtAndDeepLinkRequest } from '../../../redux/actions/getJwtAndDeeplinkReturnUrlActions.js';

function GroupRetryOptionsPage({ onResumeClick, editMode, deepLinkScreen }) {
  const dispatch = useDispatch();

  const { loading: jwtLoading } = useSelector(state => state.getJwtAndDeeplinkReturnUrl);
  const { groupGuid, products, loading: sessionStatusLoading, config } = useSelector(
    state => state.getContentSelectorStatus
  );

  const { loading: invalidateContentSelectorGroupLoading } = useSelector(state => state.invalidateContentSelectorGroup);

  const {
    lmsConfig: { token }
  } = useSelector(state => state.ngiContentSelector);

  useEffect(() => {
    dispatch(getContentSelectorStatusRequest({ token }));
  }, []);

  useEffect(() => {
    if (products && products.length === 0) {
      editMode();
    }
  }, [products]);

  const onAbandonClick = () => {
    localStorage.removeItem('items');
    dispatch(invalidateContentSelectorGroupRequest({ groupGuid, token }, editMode));
  };

  const handleOnResumeCick = () => {
    const itemsJSON = localStorage.getItem('items');
    const items = JSON.parse(itemsJSON);
    // dont create line items over and over again
    const itemsWithNoGradesync = items.map(item => ({ ...item, addGradeItems: false }));

    dispatch(
      getJwtAndDeepLinkRequest({ items: itemsWithNoGradesync, token }, () => {
        onResumeClick();

        dispatch(
          storeLineItemsRequest(
            {
              productsWithGradeItems: products,
              config: { ...config, groupGuid },
              selectedProducts: products
            },
            () => {
              localStorage.removeItem('items');
              deepLinkScreen();
            }
          )
        );
      })
    );
  };
  return (
    <>
      {(sessionStatusLoading || invalidateContentSelectorGroupLoading) && (
        <LoadingSpinner customClass={styles.loading} />
      )}
      {!sessionStatusLoading && !invalidateContentSelectorGroupLoading && (
        <div className={styles.container}>
          <div className={styles.illustration}>
            <Illustration illustrationSrc="/media/feedback/illustration-site-maintenance.svg" />
          </div>
          <h1 className={styles.header}>Grade item creation was stopped</h1>
          <p className={styles.body_text}>
            Do you want to resume grade item creation? Once it has finished your LMS can create your links.
          </p>
          <div className={styles.buttons}>
            <Button variant="outline" size="base" text="Abandon Grade and link creation" onClick={onAbandonClick} />
            <Button variant="filled" size="base" text="Resume" disabled={jwtLoading} onClick={handleOnResumeCick} />
          </div>
        </div>
      )}
    </>
  );
}

GroupRetryOptionsPage.propTypes = {
  onResumeClick: PropTypes.func,
  editMode: PropTypes.func,
  deepLinkScreen: PropTypes.func
};

export default GroupRetryOptionsPage;
